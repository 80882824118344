<template>
  <div class="page">
    <div class="pageFucView">
      <div class="pageFucView_left"></div>
      <div class="pageFucView_right">
        <el-button class="button" type="primary" icon="el-icon-plus" @click="handleEdit" size="small">添加</el-button>
      </div>
    </div>
    <el-table :data="list">
      <el-table-column
          align="center"
          label="ID"
          prop="id"
          show-overflow-tooltip
          width="100"
      />
<!--      <el-table-column-->
<!--          align="center"-->
<!--          label="编号"-->
<!--          prop="numbers"-->
<!--          show-overflow-tooltip-->
<!--      />-->
      <el-table-column
          align="center"
          label="参数名称"
          min-width="120"
          prop="name"
          show-overflow-tooltip
      />
      <el-table-column
          align="center"
          label="商品类型"
          min-width="120"
          show-overflow-tooltip>
        <template slot-scope="scope">
          {{category_name}}
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="是否可选"
          min-width="120"
          prop="check_state"
          show-overflow-tooltip
      >
        <template #default="{ row }">
          <span v-if="row.select_option == 0">单选</span>
          <span v-else-if="row.select_option == 1">多选</span>
          <span v-else>输入</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="排序"
          min-width="120"
          prop="sort"
          show-overflow-tooltip
      />
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" size="small" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" size="small" @click="handleDelete(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="searchObj.pageSize" :current-page.sync="searchObj.page" :total="pageCount" @current-change="pageChangeHandle" />
    </div>
    <edit ref="edit" :categoryId="$route.query.id" @fetch-data="getData"/>
  </div>
</template>

<script>
import Edit from './components/ParameterEdit'
import {apiDelGoodsProperty, apiGetGoodsPropertyList} from "@/request/api";

export default {
  name: 'parameterlist',
  components: {Edit},
  data() {
    return {
      list: [],
      pageCount: 0,
      searchObj: {
        page: 1,
        pageSize: 10,
        type:1,
        id:this.$route.query.id
      },
      category_name:''
    }
  },
  created() {
    this.getData()
  },
  methods: {
    handleDelete(id){
      this.$confirm('是否删除该参数?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        apiDelGoodsProperty({id}).then(res =>{
          if(res.code == 200){
            this.getData()
          }
        })
      }).catch(() => {
      });
    },
    //新增/编辑
    handleEdit(row) {
      console.log(row)
      if (row.id) {
        this.$refs['edit'].showEdit(row.id)
      } else {
        this.$refs['edit'].showEdit()
      }
    },
    pageChangeHandle(){
      this.getData()
    },
    getData(){
      apiGetGoodsPropertyList(this.searchObj).then(res => {
        if(res.code == 200){
          this.list = res.data.list
          this.pageCount = res.data.count
          this.category_name = res.data.category_name
        }
      })
    }
  },
}
</script>
<style>
.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
  margin-bottom: 80px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pageFucView_right {
  display: flex;
}
</style>


