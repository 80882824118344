<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="属性名称:" prop="name">
        <el-input v-model="form.name" size="small" placeholder="请输入属性名称"/>
      </el-form-item>
      <el-form-item label="参数类型" prop="select_option">
        <el-radio-group v-model="form.select_option" size="small">
          <el-radio-button :label="0">单选</el-radio-button>
          <el-radio-button :label="1">多选</el-radio-button>
          <el-radio-button :label="2">输入</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="排序:" prop="sort">
        <el-input v-model="form.sort" type="number" size="small" placeholder="请输入排序数字"/>
      </el-form-item>
      <el-form-item v-if="form.select_option==0 || form.select_option==1" label="参数值:">
        <el-table :data="form.children" border size="small">
          <el-table-column label="参数">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button v-if="scope.row.id" type="text" @click="delHande(scope.row.id,scope.$index)">删除</el-button>
              <el-button v-else type="text" @click="form.children.splice(scope.$index,1)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button icon="el-icon-plus" style="width: 100%" @click="addChild"></el-button>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="close">取 消</el-button>
      <el-button size="small" type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiCreateGoodsProperty, apiDelGoodsProperty, apiGetGoodsPropertyDetails} from "@/request/api";

export default {
  name: "ParameterEdit.vue",
  props:{
    categoryId:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      title: '新增参数',
      form: {
        id: '',
        name: '',
        type: 1,
        select_option:0,
        category_id:this.categoryId,
        sort: 1,
        children:[]
      },
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '属性名不能为空!'},
        ],
      },
      dialogFormVisible: false,
      delList:[]
    }
  },
  created() {
  },
  methods: {
    delHande(id,index){
      this.delList.push(id)
      this.form.children.splice(index,1)
    },
    addChild(){
      this.form.children.push({name:''})
    },
    getData(id){
      apiGetGoodsPropertyDetails({id,type:0}).then(res =>{
        if(res.code == 200){
          var children = []
          res.data.children.forEach(item => {
            var obj = {
              id:item.id,
              name:item.name
            }
            children.push(obj)
          })
          this.form= {
            id: res.data.id,
            name: res.data.name,
            type: res.data.type,
            select_option:res.data.select_option,
            category_id:res.data.category_id,
            sort: res.data.sort,
            children:children
          }
        }
      })
    },
    showEdit(row) {
      if (!row) {
        this.title = '新增参数'
      } else {
        console.log(row)
        this.title = '编辑参数'
        this.getData(row)
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      if(this.delList.length > 0){
        apiDelGoodsProperty({id:this.delList}).then(res => {})
      }
      var children = []
      if(this.form.select_option != 2){
        this.form.children.forEach(item => {
          if(item.id || item.name){
            children.push(item)
          }
        })
      }
      this.form.children = children
      apiCreateGoodsProperty(this.form).then(res=>{
        if(res.code == 200){
          this.$emit('fetch-data')
          this.close()
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
